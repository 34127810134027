import { Helmet } from "react-helmet";

import s from './NotFound404.module.css';
import { Container } from "../../Components/Container/Container";

export function NotFound404() {
  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404"/>
        <title>404</title>
      </Helmet>
      <Container>
        <h1 className={s.notFound}>Такой страницы не существует, перейдите на главную страницу</h1>
      </Container>
    </>
  )
}